.primary-learning {
  padding-top: 70px;
  width: 100%;
}

.primary-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/MIP08581-scaled.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.primary-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: white;
}

.curriculum-intro {
  padding: 80px 5%;
  text-align: center;
  background: linear-gradient(135deg, #f8f9fa 0%, #e3f2fd 100%);
}

.subjects-section {
  padding: 80px 5%;
  text-align: center;
}

.subjects-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.subject-card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.subject-card:hover {
  transform: translateY(-10px);
  border: 2px solid #ff6b6b;
}

.subject-card i {
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 20px;
}

.subject-card ul {
  list-style: none;
  padding: 0;
  margin-top: 15px;
}

.subject-card li {
  margin: 10px 0;
  color: #666;
}

.teaching-approach {
  padding: 80px 5%;
  background: #f8f9fa;
  text-align: center;
}

.approach-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.approach-item {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.approach-item:hover {
  transform: translateY(-10px);
}

.approach-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.approach-item h4,
.approach-item p {
  padding: 20px;
}

.assessment-section {
  padding: 80px 5%;
  text-align: center;
}

.assessment-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.assessment-item {
  background: #f8f9fa;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.assessment-item:hover {
  transform: translateY(-5px);
}

.assessment-item i {
  font-size: 2.5rem;
  color: #ff6b6b;
  margin-bottom: 20px;
}

.facilities-section {
  padding: 80px 5%;
  background: #004080;
  color: white;
  text-align: center;
}

.facilities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.facility-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.facility-item:hover {
  transform: translateY(-5px);
}

.facility-item i {
  font-size: 2.5rem;
  color: #ff6b6b;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .primary-hero h1 {
    font-size: 2.5rem;
  }

  .subjects-grid,
  .approach-grid,
  .assessment-content,
  .facilities-grid {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1400px) {
  .curriculum-intro,
  .subjects-section,
  .teaching-approach,
  .assessment-section,
  .facilities-section {
    padding: 100px 10%;
  }
} 