.about {
  padding-top: 70px;
  width: 100%;
}

.about-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/MIP08581-scaled.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.about-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: white;
}

.about-intro {
  display: flex;
  padding: 80px 5%;
  gap: 50px;
  align-items: center;
}

.intro-content {
  flex: 1;
}

.intro-content h2 {
  color: #004080;
  margin-bottom: 30px;
  font-size: 2.5rem;
}

.intro-image {
  flex: 1;
}

.intro-image img {
  width: 100%;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
}

.mission-vision {
  display: flex;
  padding: 60px 5%;
  gap: 40px;
  background: #f8f9fa;
}

.mission-box,
.vision-box {
  flex: 1;
  padding: 40px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.mission-box:hover,
.vision-box:hover {
  transform: translateY(-10px);
}

.mission-box i,
.vision-box i {
  font-size: 3rem;
  color: #ff6b6b;
  margin-bottom: 20px;
}

.core-values {
  padding: 80px 5%;
  text-align: center;
}

.values-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.value-item {
  padding: 30px;
  background: white;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.value-item:hover {
  transform: translateY(-5px);
}

.value-item i {
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 15px;
}

.facilities {
  padding: 80px 5%;
  background: #f8f9fa;
  text-align: center;
}

.facilities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.facility-card {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.facility-card:hover {
  transform: translateY(-10px);
}

.facility-card img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.facility-card h4,
.facility-card p {
  padding: 20px;
}

.team-section {
  padding: 80px 5%;
  text-align: center;
}

.team-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 40px;
  margin-top: 40px;
}

.team-member {
  background: white;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.team-member img {
  width: 100%;
  height: 300px;
  object-fit: cover;
}

.team-member h4,
.team-member p {
  padding: 10px 20px;
}

.position {
  color: #ff6b6b;
  font-weight: bold;
}

.achievements {
  padding: 80px 5%;
  background: #004080;
  color: white;
  text-align: center;
}

.achievements h2 {
  color: white;
}

.achievements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.achievement-item {
  padding: 30px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease;
}

.achievement-item:hover {
  transform: translateY(-5px);
}

.achievement-item i {
  font-size: 2.5rem;
  color: #ff6b6b;
  margin-bottom: 15px;
}

.achievement-item h3 {
  font-size: 2.5rem;
  color: white;
  margin: 10px 0;
}

@media (max-width: 768px) {
  .about-hero h1 {
    font-size: 2.5rem;
  }

  .about-intro {
    flex-direction: column;
    padding: 40px 20px;
  }

  .mission-vision {
    flex-direction: column;
    padding: 40px 20px;
  }

  .team-member img {
    height: 250px;
  }
}

@media (max-width: 480px) {
  .about-hero h1 {
    font-size: 2rem;
  }

  .intro-content h2 {
    font-size: 2rem;
  }

  .facilities-grid {
    grid-template-columns: 1fr;
  }
} 