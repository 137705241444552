.not-found {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #f5f7fa 0%, #e3f2fd 100%);
  padding: 20px;
}

.not-found-content {
  text-align: center;
  max-width: 800px;
  padding: 40px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.5s ease-out;
}

.error-code {
  font-size: 120px;
  font-weight: bold;
  color: #004080;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
  animation: pulse 2s infinite;
}

.not-found h1 {
  color: #2c3e50;
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.not-found p {
  color: #666;
  font-size: 1.2rem;
  margin-bottom: 40px;
}

.illustration {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  margin: 40px 0;
}

.school-icon {
  font-size: 3rem;
  color: #004080;
}

.search-icon {
  font-size: 2.5rem;
  color: #ff6b6b;
  animation: float 3s infinite;
}

.dots {
  display: flex;
  gap: 8px;
}

.dots span {
  width: 8px;
  height: 8px;
  background: #004080;
  border-radius: 50%;
  animation: bounce 0.5s infinite alternate;
}

.dots span:nth-child(2) {
  animation-delay: 0.2s;
}

.dots span:nth-child(3) {
  animation-delay: 0.4s;
}

.helpful-links {
  margin: 40px 0;
}

.helpful-links h2 {
  color: #2c3e50;
  margin-bottom: 20px;
}

.links-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.link-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background: #f8f9fa;
  border-radius: 15px;
  text-decoration: none;
  color: #004080;
  transition: all 0.3s ease;
}

.link-card:hover {
  transform: translateY(-5px);
  background: #004080;
  color: white;
}

.link-card i {
  font-size: 2rem;
  margin-bottom: 10px;
}

.back-home {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  padding: 15px 30px;
  background: #ff6b6b;
  color: white;
  text-decoration: none;
  border-radius: 30px;
  font-weight: bold;
  transition: all 0.3s ease;
}

.back-home:hover {
  background: #ff5252;
  transform: translateX(-5px);
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
  100% {
    transform: translateY(0);
  }
}

@keyframes bounce {
  from {
    transform: translateY(0);
  }
  to {
    transform: translateY(-5px);
  }
}

@media (max-width: 768px) {
  .error-code {
    font-size: 80px;
  }

  .not-found h1 {
    font-size: 2rem;
  }

  .not-found p {
    font-size: 1rem;
  }

  .links-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 480px) {
  .not-found-content {
    padding: 20px;
  }

  .links-grid {
    grid-template-columns: 1fr;
  }
} 