.sponsor {
  padding-top: 70px;
  width: 100%;
}

.sponsor-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/MIP08581-scaled.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.sponsor-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: white;
}

.support-intro {
  padding: 80px 5%;
  background: linear-gradient(135deg, #f8f9fa 0%, #e3f2fd 100%);
}

.intro-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.intro-card {
  background: white;
  padding: 40px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}

.intro-card:hover {
  transform: translateY(-10px);
}

.intro-card i {
  font-size: 3rem;
  color: #ff6b6b;
  margin-bottom: 20px;
}

.intro-card p {
  font-size: 1.1rem;
  line-height: 1.6;
  color: #444;
}

.sponsorship-info {
  padding: 100px 5%;
  background: white;
}

.info-content h3 {
  font-size: 2.2rem;
  color: #004080;
  margin-bottom: 30px;
  display: flex;
  align-items: center;
  gap: 15px;
}

.info-content h3 i {
  color: #ff6b6b;
}

.info-subtitle {
  font-size: 1.2rem;
  color: #666;
  text-align: center;
  margin-bottom: 50px;
}

.benefits-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.benefit-box {
  background: #f8f9fa;
  padding: 30px;
  border-radius: 15px;
  text-align: center;
  transition: all 0.3s ease;
  border: 2px solid transparent;
}

.benefit-box:hover {
  transform: translateY(-5px);
  border-color: #ff6b6b;
  background: white;
}

.benefit-box i {
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 20px;
}

.benefit-box h4 {
  color: #004080;
  margin-bottom: 15px;
  font-size: 1.2rem;
}

.benefit-box p {
  color: #666;
}

.sponsorship-plans {
  padding: 80px 5%;
  background: #f8f9fa;
  text-align: center;
}

.plans-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
  padding: 0 5%;
}

.plan-card {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.plan-card.featured {
  transform: scale(1.05);
  border: 2px solid #ff6b6b;
}

.plan-card:hover {
  transform: translateY(-10px);
}

.plan-header {
  background: #004080;
  color: white;
  padding: 30px;
}

.plan-header i {
  font-size: 3rem;
  margin-bottom: 20px;
}

.plan-header .price {
  font-size: 2rem;
  font-weight: bold;
  margin: 15px 0;
}

.plan-content {
  padding: 30px;
}

.plan-content button {
  background: #ff6b6b;
  color: white;
  border: none;
  padding: 12px 30px;
  border-radius: 25px;
  font-size: 1.1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 20px;
}

.plan-content button:hover {
  background: #ff5252;
  transform: scale(1.05);
}

.payment-info {
  padding: 80px 5%;
  text-align: center;
}

.payment-details {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 40px;
  margin-top: 40px;
  padding: 0 5%;
}

.bank-info,
.contact-support {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.bank-info h3,
.contact-support h3 {
  color: #004080;
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
}

.sponsor-benefits {
  padding: 80px 5%;
  background: #004080;
  color: white;
  text-align: center;
}

@media (max-width: 768px) {
  .sponsor-hero h1 {
    font-size: 2.5rem;
  }

  .plans-grid,
  .payment-details,
  .benefits-grid {
    grid-template-columns: 1fr;
  }

  .plan-card.featured {
    transform: none;
  }

  .intro-cards {
    grid-template-columns: 1fr;
  }
  
  .benefits-grid {
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  }
  
  .info-content h3 {
    font-size: 1.8rem;
  }
}

@media (min-width: 1400px) {
  .support-intro,
  .sponsorship-info,
  .plans-grid,
  .payment-details,
  .benefits-grid {
    padding: 0 15%;
  }

  .support-intro,
  .sponsorship-info {
    padding: 120px 10%;
  }
  
  .intro-card p {
    font-size: 1.2rem;
  }
  
  .benefit-box {
    padding: 40px;
  }
} 