.early-years {
  padding-top: 70px;
  width: 100%;
}

.early-years-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/MIP08581-scaled.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.early-years-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: white;
}

.program-intro {
  padding: 80px 5%;
  text-align: center;
  background: linear-gradient(135deg, #f8f9fa 0%, #e3f2fd 100%);
}

.learning-areas {
  padding: 80px 5%;
  text-align: center;
}

.areas-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.area-card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.area-card:hover {
  transform: translateY(-10px);
  border: 2px solid #ff6b6b;
}

.area-card i {
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 20px;
}

.daily-activities {
  padding: 80px 5%;
  background: #f8f9fa;
  text-align: center;
}

.activities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.activity-item {
  background: white;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.activity-item:hover {
  transform: translateY(-10px);
}

.activity-item img {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.activity-item h4,
.activity-item p {
  padding: 20px;
}

.class-structure {
  padding: 80px 5%;
  text-align: center;
}

.structure-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.age-group {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.age-group h3 {
  color: #004080;
  margin-bottom: 20px;
}

.age-group ul {
  list-style: none;
  padding: 0;
}

.age-group li {
  margin: 10px 0;
  padding-left: 20px;
  position: relative;
}

.age-group li::before {
  content: '•';
  color: #ff6b6b;
  position: absolute;
  left: 0;
}

.parent-involvement {
  padding: 80px 5%;
  background: #004080;
  color: white;
  text-align: center;
}

.partnership-content {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.partnership-item {
  background: rgba(255, 255, 255, 0.1);
  padding: 30px;
  border-radius: 15px;
  transition: transform 0.3s ease;
}

.partnership-item:hover {
  transform: translateY(-5px);
}

.partnership-item i {
  font-size: 2.5rem;
  color: #ff6b6b;
  margin-bottom: 20px;
}

@media (max-width: 768px) {
  .early-years-hero h1 {
    font-size: 2.5rem;
  }

  .areas-grid,
  .activities-grid,
  .structure-content,
  .partnership-content {
    grid-template-columns: 1fr;
  }
}

@media (min-width: 1400px) {
  .program-intro,
  .learning-areas,
  .daily-activities,
  .class-structure,
  .parent-involvement {
    padding: 100px 10%;
  }
} 