header {
  height: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 50px;
  background: #004080;
  color: white;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  box-shadow: 0 2px 5px rgba(0,0,0,0.1);
}

.logo {
  display: flex;
  align-items: center;
}

.logo a {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: white;
}

.logo-img {
  height: 50px;
  width: auto;
  margin-right: 15px;
}

.school-name {
  font-size: 1.4rem;
  font-weight: bold;
  color: white;
  transition: color 0.3s ease;
}

.logo a:hover .school-name {
  color: #ff6b6b;
}

.nav-links {
  display: flex;
  align-items: center;
  gap: 40px;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  padding: 10px 20px;
  border-radius: 5px;
  position: relative;
}

.nav-links a::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 0;
  height: 2px;
  background: #ff6b6b;
  transition: width 0.3s ease;
}

.nav-links a:hover::after {
  width: 80%;
}

.admission-btn {
  background: #ff6b6b;
  padding: 12px 30px !important;
  border-radius: 25px;
  font-weight: bold;
  transition: all 0.3s ease;
  margin-left: 20px;
}

.admission-btn:hover {
  background: #ff5252 !important;
  transform: scale(1.05);
}

.admission-btn::after {
  display: none !important;
}

.mobile-menu-btn {
  display: none;
  background: none;
  border: none;
  cursor: pointer;
  padding: 10px;
  z-index: 1001;
}

.hamburger {
  display: block;
  width: 25px;
  height: 3px;
  background: white;
  position: relative;
  transition: all 0.3s ease-in-out;
}

.hamburger::before,
.hamburger::after {
  content: '';
  position: absolute;
  width: 25px;
  height: 3px;
  background: white;
  transition: all 0.3s ease-in-out;
}

.hamburger::before {
  transform: translateY(-8px);
}

.hamburger::after {
  transform: translateY(8px);
}

.hamburger.active {
  background: transparent;
}

.hamburger.active::before {
  transform: rotate(45deg);
}

.hamburger.active::after {
  transform: rotate(-45deg);
}

@media screen and (max-width: 1024px) {
  header {
    padding: 0 30px;
  }
  
  .nav-links {
    gap: 20px;
  }
  
  .nav-links a {
    font-size: 0.9rem;
  }
}

@media screen and (max-width: 768px) {
  .mobile-menu-btn {
    display: block;
  }
  
  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    max-width: 300px;
    background: #004080;
    flex-direction: column;
    padding: 80px 30px;
    transition: right 0.3s ease-in-out;
    box-shadow: -2px 0 5px rgba(0,0,0,0.1);
  }
  
  .nav-links.active {
    right: 0;
  }
  
  .nav-links a {
    width: 100%;
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }
  
  .admission-btn {
    margin-top: 20px;
    width: 100%;
  }
  
  .logo-img {
    height: 40px;
  }
  
  .school-name {
    font-size: 1.1rem;
  }
}

@media screen and (max-width: 480px) {
  header {
    padding: 0 20px;
  }
  
  .logo a {
    font-size: 1.2rem;
  }
  
  .nav-links {
    width: 100%;
    max-width: none;
  }
}

/* Desktop-specific styles */
@media screen and (min-width: 1200px) {
  header {
    padding: 0 80px;
  }

  .nav-links {
    gap: 50px;
  }
}

.dropdown {
  position: relative;
  cursor: pointer;
}

.dropdown-trigger {
  color: white;
  padding: 10px 20px;
  display: inline-block;
  position: relative;
}

.dropdown-trigger::after {
  content: '▼';
  font-size: 0.8em;
  margin-left: 5px;
}

.dropdown-content {
  position: absolute;
  top: 100%;
  left: 0;
  background: #004080;
  min-width: 200px;
  border-radius: 5px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  opacity: 0;
  visibility: hidden;
  transform: translateY(-10px);
  transition: all 0.3s ease;
  z-index: 1000;
}

.dropdown-content.show {
  opacity: 1;
  visibility: visible;
  transform: translateY(0);
}

.dropdown-content a {
  display: block;
  padding: 12px 20px;
  color: white;
  text-decoration: none;
  transition: all 0.3s ease;
}

.dropdown-content a:hover {
  background: rgba(255, 255, 255, 0.1);
}

@media screen and (max-width: 768px) {
  .dropdown {
    width: 100%;
  }

  .dropdown-trigger {
    width: 100%;
    text-align: center;
    padding: 15px;
    border-bottom: 1px solid rgba(255,255,255,0.1);
  }

  .dropdown-content {
    position: static;
    width: 100%;
    opacity: 1;
    visibility: visible;
    transform: none;
    box-shadow: none;
    background: rgba(0, 0, 0, 0.1);
    display: none;
  }

  .dropdown-content.show {
    display: block;
  }
} 