.home {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 70px;
}

.hero-section,
.features-section,
.welcome-message,
.highlights {
  width: 100%;
  position: relative;
}

.hero-section {
  width: 100%;
  height: calc(100vh - 70px);
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
              url('https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/MIP08581-scaled.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  margin: 0;
  padding: 0;
}

.hero-section::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(to top, #fff, transparent);
}

.hero-section h1 {
  font-size: 4.5rem;
  margin-bottom: 20px;
  animation: fadeInDown 1s ease-out;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

.tagline {
  font-size: 2rem;
  animation: fadeInUp 1s ease-out;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  margin-bottom: 30px;
}

.hero-cta {
  margin-top: 30px;
}

.hero-cta button {
  padding: 15px 40px;
  font-size: 1.2rem;
  border: none;
  border-radius: 50px;
  background: #ff6b6b;
  color: white;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: pulse 2s infinite;
}

.hero-cta button:hover {
  background: #ff5252;
  transform: scale(1.05);
}

.features-section {
  display: flex;
  justify-content: space-around;
  padding: 100px 5%;
  background: linear-gradient(135deg, #f9f9f9 0%, #f0f7ff 100%);
  flex-wrap: wrap;
}

.feature-card {
  flex: 1;
  min-width: 300px;
  max-width: none;
  text-align: center;
  padding: 30px;
  margin: 20px 2%;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.feature-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  background: linear-gradient(90deg, #ff6b6b, #4ecdc4);
}

.feature-card:hover {
  transform: translateY(-15px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.feature-card img {
  width: 100%;
  height: 250px;
  object-fit: cover;
  border-radius: 15px;
  margin-bottom: 20px;
  transition: transform 0.3s ease;
}

.feature-card:hover img {
  transform: scale(1.05);
}

.welcome-message {
  display: flex;
  padding: 120px 5%;
  background: white;
  align-items: center;
  gap: 60px;
}

.message-content {
  flex: 1;
  padding-right: 40px;
}

.message-content h2 {
  font-size: 2.5rem;
  margin-bottom: 30px;
  color: #2c3e50;
}

.message-image {
  flex: 1;
  position: relative;
}

.message-image img {
  width: 100%;
  border-radius: 20px;
  box-shadow: 20px 20px 60px rgba(227, 212, 124, 0.1);
  transition: transform 0.3s ease;
}

.message-image:hover img {
  transform: scale(1.02);
}

.highlights {
  background: linear-gradient(135deg, #f0f7ff 0%, #e3f2fd 100%);
  padding: 120px 5%;
  text-align: center;
}

.highlights h2 {
  font-size: 2.5rem;
  margin-bottom: 50px;
}

.highlights-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 40px;
  margin-top: 40px;
  padding: 0;
  max-width: none;
  width: 100%;
  gap: 30px;
}

.highlight-item {
  padding: 40px 30px;
  background: white;
  border-radius: 20px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.highlight-item:hover {
  transform: translateY(-10px);
  box-shadow: 0 15px 30px rgba(0, 0, 0, 0.2);
}

.highlight-item i {
  font-size: 3rem;
  color: #4a90e2;
  margin-bottom: 20px;
  transition: all 0.3s ease;
}

.highlight-item:hover i {
  transform: scale(1.2);
  color: #ff6b6b;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 0 rgba(255, 107, 107, 0.4);
  }
  70% {
    box-shadow: 0 0 0 20px rgba(255, 107, 107, 0);
  }
  100% {
    box-shadow: 0 0 0 0 rgba(255, 107, 107, 0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 1200px) {
  .hero-section h1 {
    font-size: 3.5rem;
  }
  
  .welcome-message {
    padding: 60px 40px;
  }
}

@media (max-width: 992px) {
  .hero-section h1 {
    font-size: 3rem;
  }
  
  .welcome-message {
    flex-direction: column;
    text-align: center;
    padding: 40px 20px;
  }
  
  .message-content {
    padding-right: 0;
  }
}

@media (max-width: 768px) {
  .hero-section h1 {
    font-size: 2.5rem;
  }
  
  .tagline {
    font-size: 1.5rem;
  }
  
  .feature-card {
    width: 100%;
    max-width: 400px;
    margin: 20px auto;
  }
  
  .highlights-grid {
    gap: 20px;
  }
}

@media (max-width: 480px) {
  .hero-section h1 {
    font-size: 2rem;
  }
  
  .hero-cta button {
    padding: 12px 30px;
    font-size: 1rem;
  }
  
  .highlights {
    padding: 60px 20px;
  }
}

@media screen and (max-height: 800px) {
  .hero-section {
    padding: 80px 20px;
  }
  
  .features-section,
  .welcome-message,
  .highlights {
    padding: 60px 20px;
  }
}

@media screen and (max-height: 600px) {
  .hero-section {
    height: auto;
    min-height: 100vh;
  }
}

@media screen and (min-width: 1400px) {
  .hero-section h1 {
    font-size: 5rem;
  }

  .tagline {
    font-size: 2.5rem;
  }

  .feature-card {
    width: 400px;
  }

  .message-content h2 {
    font-size: 3rem;
  }

  .message-content p {
    font-size: 1.2rem;
    line-height: 1.8;
  }

  .highlights h2 {
    font-size: 3rem;
  }

  .highlight-item {
    padding: 50px 40px;
  }

  .highlight-item i {
    font-size: 3.5rem;
  }

  .highlight-item h4 {
    font-size: 1.5rem;
  }

  .highlight-item p {
    font-size: 1.1rem;
  }

  .features-section {
    padding: 100px 3%;
  }

  .welcome-message {
    padding: 120px 3%;
  }

  .highlights {
    padding: 120px 3%;
  }

  .feature-card {
    margin: 20px 1%;
  }
} 