.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  position: relative;
  overflow-x: hidden;
  margin: 0;
  padding: 0;
}

main {
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%;
}
