/* index.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html, 
body, 
#root {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
}

body {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
}

h1{
  color: rgb(206, 161, 15);
}

h2, h3, h4 {
  color: #2c3e50;
  margin-bottom: 15px;
}

p {
  margin-bottom: 15px;
}

img {
  max-width: 100%;
}

.page {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
