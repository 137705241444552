.contact {
  padding-top: 70px;
  width: 100%;
}

.contact-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/MIP08581-scaled.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 80px 20px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.contact-hero h1 {
  font-size: 3rem;
  margin-bottom: 20px;
  color: white;
}

.contact-container {
  display: flex;
  width: 100%;
  margin: 50px auto;
  padding: 0 5%;
  gap: 50px;
}

.contact-info {
  flex: 1;
  background: #f8f9fa;
  padding: 40px;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

.info-section {
  margin-bottom: 30px;
}

.info-section h3 {
  color: #004080;
  margin-bottom: 15px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.info-section i {
  color: #ff6b6b;
}

.social-links {
  margin-top: 30px;
}

.social-icons {
  display: flex;
  gap: 20px;
  margin-top: 15px;
}

.social-icons a {
  color: #004080;
  font-size: 24px;
  transition: color 0.3s ease;
}

.social-icons a:hover {
  color: #ff6b6b;
}

.contact-form {
  flex: 1;
}

.contact-form h2 {
  color: #004080;
  margin-bottom: 30px;
}

.form-group {
  margin-bottom: 20px;
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #004080;
  outline: none;
}

.submit-btn {
  background: #ff6b6b;
  color: white;
  padding: 12px 30px;
  border: none;
  border-radius: 25px;
  font-size: 16px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background: #ff5252;
  transform: translateY(-2px);
}

.map-container {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  height: 450px;
  padding: 0 5%;
}

.map-container iframe {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
}

/* Desktop styles */
@media (min-width: 1200px) {
  .contact-container {
    padding: 0 10%;
  }

  .map-container {
    padding: 0 10%;
  }
}

@media (min-width: 1400px) {
  .contact-container {
    padding: 0 15%;
  }

  .map-container {
    padding: 0 15%;
  }
}

/* Existing mobile styles remain the same */
@media (max-width: 768px) {
  .contact-container {
    flex-direction: column;
    padding: 0 20px;
  }

  .contact-hero h1 {
    font-size: 2.5rem;
  }

  .contact-info,
  .contact-form {
    width: 100%;
  }

  .map-container {
    height: 350px;
    padding: 0 20px;
  }
}

@media (max-width: 480px) {
  .contact-hero h1 {
    font-size: 2rem;
  }

  .contact-container {
    margin: 30px auto;
  }

  .social-icons {
    gap: 15px;
  }

  .map-container {
    height: 300px;
  }
} 