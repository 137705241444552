.gallery {
  padding-top: 70px;
  width: 100%;
}

.gallery-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/MIP08581-scaled.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.gallery-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: white;
}

.gallery-container {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 30px;
  padding: 40px 5%;
  width: 100%;
  transition: all 0.3s ease;
}

.gallery-item {
  position: relative;
  overflow: hidden;
  border-radius: 10px;
  cursor: pointer;
  aspect-ratio: 4/3;
  animation: fadeIn 0.5s ease-in-out;
}

.gallery-item img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.1);
}

.image-overlay {
  position: absolute;
  bottom: -100%;
  left: 0;
  right: 0;
  background: linear-gradient(transparent, rgba(0, 0, 0, 0.8));
  color: white;
  padding: 20px;
  transition: bottom 0.3s ease;
}

.gallery-item:hover .image-overlay {
  bottom: 0;
}

.image-overlay h3 {
  color: white;
  margin-bottom: 5px;
  font-size: 1.2rem;
}

.image-overlay p {
  color: #ff6b6b;
  font-size: 0.9rem;
  margin: 0;
}

.lightbox {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  padding: 20px;
}

.lightbox img {
  max-width: 90%;
  max-height: 80vh;
  object-fit: contain;
  border-radius: 10px;
}

.close-btn {
  position: absolute;
  top: 20px;
  right: 30px;
  color: white;
  font-size: 3rem;
  cursor: pointer;
  transition: color 0.3s ease;
}

.close-btn:hover {
  color: #ff6b6b;
}

.image-info {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  text-align: center;
  color: white;
  background: rgba(0, 0, 0, 0.5);
  padding: 15px;
}

.gallery-categories {
  padding: 40px 5%;
  text-align: center;
  background: #f8f9fa;
  border-bottom: 1px solid #eee;
}

.category-btn {
  padding: 12px 25px;
  margin: 5px;
  border: none;
  border-radius: 25px;
  background: white;
  color: #004080;
  font-size: 1rem;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border: 2px solid transparent;
}

.category-btn:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.category-btn.active {
  background: #004080;
  color: white;
  border-color: #004080;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@media (max-width: 768px) {
  .gallery-hero h1 {
    font-size: 2.5rem;
  }

  .gallery-container {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    padding: 20px;
  }

  .gallery-categories {
    padding: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }

  .category-btn {
    padding: 10px 20px;
    font-size: 0.9rem;
  }
}

@media (min-width: 1200px) {
  .gallery-categories {
    padding: 50px 10%;
  }

  .category-btn {
    padding: 15px 30px;
    font-size: 1.1rem;
    margin: 0 10px;
  }

  .gallery-container {
    padding: 60px 10%;
  }

  .gallery-item {
    aspect-ratio: 16/9;
  }
} 