.watermark {
  position: fixed;
  bottom: 80px;
  right: 20px;
  background: rgba(0, 0, 0, 0.85);
  padding: 12px 20px;
  border-radius: 30px;
  z-index: 999;
  cursor: pointer;
  transition: all 0.3s ease;
  border: 1px solid rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(5px);
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.watermark-content {
  display: flex;
  align-items: center;
  gap: 12px;
}

.watermark i {
  font-size: 1.5rem;
  color: #25D366;  /* WhatsApp green for mobile */
}

.watermark-text {
  display: flex;
  flex-direction: column;
}

.watermark p {
  margin: 0;
  color: white;
  font-size: 0.95rem;
}

.watermark small {
  color: #aaa;
  font-size: 0.75rem;
}

.pearlk-link {
  color: #ff6b6b;
  font-weight: bold;
}

.watermark:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.4);
}

.watermark:hover .pearlk-link {
  color: #ff5252;
}

.watermark:hover i {
  transform: scale(1.1);
}

@media (max-width: 768px) {
  .watermark {
    bottom: 90px;
    right: 15px;
    padding: 10px 15px;
  }
  
  .watermark p {
    font-size: 0.9rem;
  }
  
  .watermark small {
    font-size: 0.7rem;
  }
} 