.admission {
  padding-top: 70px;
  width: 100%;
}

.admission-hero {
  background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
              url('https://mwlgmmemorial.sc.tz/wp-content/uploads/2024/06/MIP08581-scaled.jpg');
  background-size: cover;
  background-position: center;
  color: white;
  text-align: center;
  padding: 100px 20px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
}

.admission-hero h1 {
  font-size: 3.5rem;
  margin-bottom: 20px;
  color: white;
}

.admission-intro {
  padding: 80px 5%;
  text-align: center;
  background: linear-gradient(135deg, #f8f9fa 0%, #e3f2fd 100%);
}

.key-points {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.point-card {
  background: white;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
}

.point-card:hover {
  transform: translateY(-5px);
}

.point-card i {
  font-size: 2.5rem;
  color: #ff6b6b;
  margin-bottom: 20px;
}

.requirements {
  padding: 80px 5%;
  text-align: center;
}

.requirements-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 30px;
  margin-top: 40px;
}

.requirement-item {
  background: #f8f9fa;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.requirement-item:hover {
  transform: translateY(-5px);
  background: white;
  border: 2px solid #ff6b6b;
}

.requirement-item i {
  font-size: 2.5rem;
  color: #004080;
  margin-bottom: 20px;
}

.fee-structure {
  padding: 80px 5%;
  background: #f8f9fa;
}

.fee-table-container {
  overflow-x: auto;
  margin-top: 40px;
  background: white;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.fee-table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
}

.fee-table th,
.fee-table td {
  padding: 15px;
  text-align: left;
  border-bottom: 1px solid #ddd;
}

.fee-table th {
  background: #004080;
  color: white;
}

.fee-table tr:hover {
  background: #f5f5f5;
}

.total-row {
  font-weight: bold;
  background: #f0f7ff;
}

.additional-requirements {
  padding: 80px 5%;
  text-align: center;
}

.requirements-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 20px;
  margin-top: 40px;
}

.list-item {
  display: flex;
  align-items: center;
  gap: 15px;
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.list-item i {
  color: #4CAF50;
  font-size: 1.5rem;
}

.payment-info {
  padding: 80px 5%;
  background: #004080;
  color: white;
  text-align: center;
}

.bank-details {
  background: rgba(255, 255, 255, 0.1);
  padding: 40px;
  border-radius: 15px;
  margin-top: 40px;
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
}

.payment-note,
.warning-note {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-top: 30px;
  padding: 20px;
  border-radius: 10px;
}

.payment-note {
  background: rgba(255, 255, 255, 0.2);
}

.warning-note {
  background: rgba(255, 107, 107, 0.3);
}

.payment-note i,
.warning-note i {
  font-size: 2rem;
}

@media (max-width: 768px) {
  .admission-hero h1 {
    font-size: 2.5rem;
  }

  .fee-table-container {
    margin: 20px -20px;
    border-radius: 0;
  }

  .bank-details {
    padding: 20px;
  }
}

@media (min-width: 1400px) {
  .admission-intro,
  .requirements,
  .fee-structure,
  .additional-requirements,
  .payment-info {
    padding: 100px 10%;
  }
} 